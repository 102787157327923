/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Uno de", React.createElement(_components.a, {
    href: "http://litox.entramado.net/wp-content/uploads/sites/6/ifttt_logo1.png"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 118px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 48.305084745762706%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABSElEQVQoz61RXyuDcRTeVyAfwicgV5IUCZsZTVGT7Yay/CkjSrlSm+zCxRq5cG0oVxKKK7vhytTeUbYaDfVmtvd3znn0M7MpN+LpXDzn1NN5znlsAFhAAiIiZYEUmEgpUpoIKaUUiMCaCSmIoAwb/gAtPn+ijWesroXXh9oR8mAnGPb0hDwO7K7EF0e89o5iZFZFA77ezotpF7JJBiD8KY7eU38K3VNL7oZ6LDuwHx5wOe19LhwE9wLe2uaufHS+uLlQ12KP+d14NFS1eDutRtNweseGa2yI+AHMZWgmrY2dmmhNogBYQJuBY1MPSapsb2Vk8Bq+E2MidoYHA8DkDY0nGOCjHDfGuUBsMTfF+TDHgHwTZy0kXnEruCtPU29I5jV5Ubg0dRwCXJm61ZZ/+LYQyALT775dypk/qoQvLlK5kKSy8x9yfgc3DRlh2AsXnAAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"ifttt_logo\"\n        title=\"ifttt_logo\"\n        src=\"/static/c768a5941d42d122325179bad8200809/9e4e1/ifttt_logo1.png\"\n        srcset=\"/static/c768a5941d42d122325179bad8200809/9e4e1/ifttt_logo1.png 118w\"\n        sizes=\"(max-width: 118px) 100vw, 118px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), " los servicios que más le está siendo útil a los usuarios intensivos de la nube es IFTTT, este no es mi caso pero me ha llamado la atención su funcionamiento y útil que puede llegar a ser en ciertos contextos. La aplicación no puede ser más sencilla, se eligen una serie de eventos, que ahí llama ", React.createElement(_components.em, null, "triggers"), " y una serie de acciones a reproducir cuando se produzcan esos ", React.createElement(_components.em, null, "triggers"), ". Parece una tontería pero con este mecanismo tan sencillo podemos automatizar una gran cantidad de tareas en la nube. ¿Y si como yo no somos unos fans de la nube? Pues lo tenemos crudo con IFTTT porque solamente acepta los servicios en la nube más conocidos. Pero no os preocupéis podemos hacer uso de una alternativa libre, como ", React.createElement(_components.a, {
    href: "https://github.com/ottawaruby/whenbot#readme"
  }, "Whenbot"), ", no me preguntéis como funciona porque estoy bastante verde en el tema de las APIs, pero por lo visto corre sobre Heroku, un servicio que da acceso a APIs de distintos servicios de una forma sencilla. De todas formas pienso que se podría personalizar para instalarlo en tu propio servidor y con las APIs que tu decidas."), "\n", React.createElement(_components.p, null, "Todo esto me lleva a pensar que se pueden crear controladores muy básicos con estructuras de este tipo, pongamos un ejemplo:"), "\n", React.createElement(_components.p, null, "Tenemos un sensor de temperatura con acceso a nuestra red y una estufa eléctrica sin control ninguno. Podemos ", React.createElement(_components.a, {
    href: "http://www.electroensaimada.com/control-temperatura.html"
  }, "con una Raspberri Pi hacer un pequeño programa que encienda o apague la estufa"), ", luego con el Whenbot programarlo para que cuando la temperatura leída del sensor baje de cierto umbral ejecute el programa."), "\n", React.createElement(_components.p, null, "De esta forma tenemos el tipo de control más básico, un todo o nada, que es un tipo de control muy intuitivo que cualquier persona sin conocimientos de control podría programar. Los problemas que presenta se encuentran en las zonas límite, pueden aparecer oscilaciones molestas si la temperatura se encuentra todo el rato cerca de la temperatura umbral. Pero este es un primer paso, todo se puede ampliar."), "\n", React.createElement(_components.p, null, "Como podríamos conectar a la red distintos dispositivos y hacer que se comunicaran entre ellos obtendríamos una red de control distribuido muy configurable a nuestros gustos, por ejemplo en el campo de la domótica, pero también en muchos otros."), "\n", React.createElement(_components.p, null, "Continuando con este campo podríamos instalar contadores digitales, ", React.createElement(_components.a, {
    href: "http://www.rtve.es/noticias/20130123/enchufes-inteligentes-para-reducir-consumo-gasto-electricidad-casa/604562?goback=.gde_2703798_member_219145502"
  }, "enchufes inteligentes"), ", servidores, ", React.createElement(_components.em, null, "media centers"), " y toda una infraestructura de ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2012/11/18/red-de-hogar/"
  }, "red de hogar"), " que se controlaría ella sola en una mezcla entre vivienda digital y automatizada que podríamos supervisar desde cualquier lugar del mundo gracias a las tecnologías Web."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
